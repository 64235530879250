@import '~bootstrap/scss/bootstrap.scss';
/********基本***************/
.container {
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.font-setting {
  text-align: center;
  font: 50px "Microsoft Yahei";
  color: #fff;
}

.home-section {
  @extend .font-setting;
  background: url("~@/assets/images/calm_ocean_at_sunset-wallpaper.jpg") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.profile-section {
  @extend .font-setting;
  background: url("~@/assets/images/Chalk-board-wallpaper-hd.jpg") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.experience-section {
  @extend .font-setting;
  background: url("~@/assets/images/bg2.jpg") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.portfolio-section {
  background: url("~@/assets/images/bg1.jpg") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.future-section {
  @extend .font-setting;
  background: url("~@/assets/images/pure.jpg") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.skill-section {
  @extend .font-setting;
  background: url("~@/assets/images/Chalk-board-wallpaper-hd.jpg") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.contact-section {
  background: url("~@/assets/images/pure.jpg") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.introduction-section {
  -webkit-background-size: cover;
  background-size: cover;
}

.page-content {
  display: none;
}

.page-content.visible {
  display: block;
}

.page-content.hidden {
  display: none;
}

/********基本END***************/

body {
  font-family: microsoft yahei, sans-serif;
  -webkit-tap-highlight-color: transparent;
}

a {
  color: #ffffff;
}

/********************右側導航欄*************************************/

#nav-dot {
  top: 75%;
  position: fixed;
  margin-top: -48px;
  right: 17px;
}

#nav-dot ul li {
  vertical-align: middle;
  margin: 10px 50px 0;
}

#nav-dot li .active {
  background-position: 0 bottom;
}

#nav-dot ul li a {
  display: block;
  color: #8f9da4;
  width: 21px;
  height: 21px;
  background: url(~@/assets/images/dot.png) 5px 5px no-repeat;
}

/****************************右側導航欄End******************************/
