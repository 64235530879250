.carousel-item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: calc(100vh - 40px); /* 减去标题的高度 */
}

img {
  max-width: 100%;
  max-height: 100vh;
  object-fit: contain;
}

.carousel-item-content {
  width: 100%;
  background-color: black;
  color: white;
  font-size: 1em;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.custom-dot-list-style {
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.custom-dot {
  background: #000000;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  cursor: pointer;
}

.custom-dot--active {
  background: #ffffff;
}