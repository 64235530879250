$black: #000;
$white: #fff;
$font-family: microsoft yahei, sans-serif;

.license-slide {
  margin-bottom: 30px;
  text-align: center;
  font-size: 38px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: 300px;
  }

  span {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $black;
    color: $white;
    font-size: 20px;
  }
}

.subject-group {
  margin-top: 10px;

  .subject-text {
    position: absolute;
    left: 60%;
    color: $black;
    transform: scale(0.5);
    animation: bounceIn 2s linear forwards;
  }

  .subject-1 {
    @extend .subject-text;
    font-size: 48px;
    margin-left: -520px;
    margin-top: -30px;
  }

  .subject-2 {
    @extend .subject-text;
    font-size: 18px;
    margin-left: -250px;
    margin-top: 40px;
  }

  .subject-3 {
    @extend .subject-text;
    font-size: 14px;
    margin-left: -360px;
    margin-top: 45px;
  }

  .subject-4 {
    @extend .subject-text;
    font-size: 20px;
    margin-left: -270px;
    margin-top: -30px;
  }

  .subject-5 {
    @extend .subject-text;
    font-size: 20px;
    margin-left: -120px;
    margin-top: 50px;
  }

  .subject-6 {
    @extend .subject-text;
    font-size: 30px;
    margin-left: 133px;
    margin-top: -15px;
  }

  .subject-7 {
    @extend .subject-text;
    font-size: 16px;
    margin-left: 10px;
    margin-top: -15px;
  }

  .subject-8 {
    @extend .subject-text;
    font-size: 20px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .subject-9 {
    @extend .subject-text;
    font-size: 18px;
    margin-left: 2px;
    margin-top: 41px;
  }

  .subject-10 {
    @extend .subject-text;
    font-size: 18px;
    margin-left: -150px;
    margin-top: 10px;
  }
}

.introduction-paragraph {
  a {
    display: block;
    width: 40px;
    height: 40px;
  }

  .introduction-content {
    font-size: 0.9em;
    color: $black;
    font-family: $font-family;
  }

  .media {
    margin-bottom: 20px;

    &:nth-child(1) {
      opacity: 1;
      animation: bounceInLeft 2s linear forwards;
    }

    &:nth-child(2) {
      opacity: 1;
      animation: bounceInRight 2s linear forwards;
      animation-delay: 1s;
    }

    &:nth-child(3) {
      opacity: 1;
      animation: bounceInLeft 2s linear forwards;
      animation-delay: 2s;
    }
  }
}

.introduction-title {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .introduction-icon {
    display: block;
    width: 40px;
    height: 40px;

    img {
      width: 100%;
    }
  }

  .introduction-heading {
    margin-left: 10px;
    font-size: 1.3rem;
  }
}