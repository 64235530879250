.experience-title {
  display: inline-block;
  -webkit-animation: flipInY 2s linear;
  animation: flipInY 2s linear;
}

.experience {
  height: 100px;
}
.team-img {
  width: 120px;
  height: 120px;
}
.thumbnail {
  display: block;
  background-color: transparent;
  border: 0;
}

.thumbnail .caption {
  color: rgb(255, 140, 0);
  text-shadow: 1px 1px 1px #4442a9;

  font-family: inherit;
}
h3 {
  font-size: 16px;
  font-weight: 700;
}
p {
  font-size: 16px;
  font-weight: 700;
}
.thumbnail .team-img {
  border: 4px solid transparent;
  -webkit-animation: team-img 3s linear infinite;
  animation: team-img 3s linear infinite;
}
.thumbnail {
  margin: 0;
}