.resume-name {
  text-shadow: 9px -2px 15px #000;
}

.resume-name-big {
  @extend .resume-name;
  text-align: center;
  font-size: 50px;
  text-shadow: 5px -2px 8px #151414;
}

.motto {
  font-size: 25px;
}

.next-page {
  position: absolute;
  margin-left: -35px;
  left: 50%;
  bottom: 10%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.next-page,
.next-page:hover,
.next-page:focus {
  color: yellowgreen;
}

.next-page {
  line-height: 80px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  animation: fadeInUp 2s ease;
  -webkit-animation: fadeInUp 2 ease;
}

.next-page:hover {
  background: rgba(51, 51, 51, 1);
}

.next-page svg {
  font-size: 30px;
  animation: slideInDown 1s infinite;
}

.word > .resume-name-big,
.word > .resume-name {
  display: inline-block;
  -webkit-animation: fadeInDownBig 2s ease;
  animation: fadeInDownBig 2s ease;
}

.word p {
  -webkit-animation: p-fly 2s ease;
  animation: p-fly 2s ease;
}

.social-links {
  font-size: 3rem;
}

.intro-section {
  margin: 0 auto;
  padding-top: 5px;
  font-size: 16px;
  width: 200px;
  text-shadow: 0px 0px 6px #000;

  span svg {
    margin-left: 5px;
  }

  .intro-section-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
}

/********************首頁***************************************/
.userPic {
  margin: auto;
  align-items: center;
  display: flex;
  justify-content: center;
}

.sec1_userPic {
  position: relative;
  width: 170px;
  height: 170px;
  background-image: url(~@/assets/images/home/userPic.jpg);
  background-size: 100% 100%;
  border: 5px solid #fff;
}

/********************首頁***************************************/