.resume {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 10;
  border: none;
}

.navbar {
  background: rgba(0, 0, 0, 0) !important;
  transition: 1s all ease;
}

.navbar.blue {
  background: rgba(71, 166, 173, 1) !important;
}

.navbar.black {
  background: rgba(51, 51, 51, 0.7) !important;
}

.resume .navbar-brand,
.resume .navbar-brand:visited,
.resume .navbar-brand:hover {
  color: #a7e615;
  font-size: 18px;
}

.resume .navbar-right li a,
.resume .navbar-right li a:visited {
  color: #ffffff !important;
  font-weight: 500;
  text-decoration: none;
}

.resume .navbar-right li a:hover {
  color: #ffffff !important;
  text-decoration: none;
}

.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:visited,
.navbar-default .navbar-nav > .active > a:focus {
  color: #ffffff;
  background-color: #0f698a;
  text-decoration: none;
}

.language-selector {
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;

  option {
    background-color: gray;
  }
}

#responsive-toggle {
  width: auto !important;
}